
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { getQuestionsList, getTopicAnsStatus } from "../../api/questions/authentication";
import Skeleton from "react-loading-skeleton";
import RefreshIcon from '@material-ui/icons/Refresh';

const PoolStatus = forwardRef((props,ref)=> {

    const abortControler = useRef();
    const [state, setState] = useState({isLoading:false,listData:null })
    const getOptionsList = ()=>{
        if(props?.id){

            if (abortControler?.current !== null) {
                abortControler?.current?.abort();
            }
            abortControler.current = new AbortController();
            setState(state=>({...state,isLoading:true}))
            getTopicAnsStatus(localStorage.getItem("voting_token"),props.id,abortControler.current).then(res=>{
                setState(state=>({...state,isLoading:false,listData:res.data}))
            }).then(error=>{
                setState(state=>({...state,isLoading:false}))
            })
        }
       
    }
    
    useImperativeHandle(ref, () => ({
        refreshStatusData: () => {
            getOptionsList()
        }

    }));
   useEffect(()=>{
    getOptionsList()
   },[props?.id])
   

   const getWidthPercntage = (item)=>{
    return `${item?.answers_count>0 ? ( item?.answers_count/state.listData.total_ans_counts) *100 : 0}%`
   }

   const getPercntageValue = (item)=>{
    return `${item?.answers_count>0 ? ((item?.answers_count/state.listData.total_ans_counts) *100).toFixed(2) : 0}% (${item?.answers_count ?? "0"})`
   }
    return (
        <>
            <div className="card h-100 mb-lg-0">
                <div className="card-body">
            
                    <div className="d-flex mb-3">
                        <div className="flex-grow-1">
                            <h5 className="card-title ">Statistics</h5>
                        </div>
                       
                           
                        <RefreshIcon className="flex-shrink-0 mt-0 cursor-pointer" onClick={getOptionsList} />
                 
                    </div>

                    <div className="d-flex mb-3 mt-3 border-bottom">
                        <div className="flex-grow-1">
                            <h6 className="card-title text-muted">Total Voters</h6>
                        </div>
                       
                           
                        <h6 className="flex-shrink-0 mt-0 mx-2">{state.listData?.total_ans_counts ?? "0"}</h6>
                 
                    </div>
                  

                    <div>
                        { state.isLoading ?  <Skeleton count={4} height={45}  style={{marginTop:30}}/>:
                            state.listData?.options?.map((item)=>{

                                return (<div className="py-3" key={item.id}>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <h5 className="font-size-14">{item.option_value}</h5>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <p className="text-muted mb-0">{getPercntageValue(item)}</p>
                                    </div>
                                </div>
                                <div className="progress animated-progess custom-progress mt-2">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: getWidthPercntage(item)}}
                                        aria-valuenow={60}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                            </div>)
                            })
                        }
                        
                    </div>
                </div>
            </div>

        </>
    )
    
})
export default PoolStatus;