import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';

const BASE_PATH = process.env.REACT_APP_QUESTION_SERVICE || 'http://localhost:2001';

export const getQuestionsList = (offset,limit) => {
    // data = encrypt(data);

    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route: `v1/questions?offset=${offset}&limit=${limit}`,
    });
}


export const getOptionsList = (token,id, controller) => {
    // data = encrypt(data);

    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route: `v1/questions/options?id=${id}`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        signal: controller?.signal,
    });
}
// export const getTopicPoolStatus = (token,id,controller) => {
//     // data = encrypt(data);

//     return invoke({
//         method: 'GET',
//         baseURL: BASE_PATH,
//         route: `v1/questions/options?id=${id}`,
//         headers: {
//             Authorization: `Bearer ${token}`
//         },
//         signal: controller?.signal,
//     });
// }

export const addTopicAnswer = (token, topicID, optionID) => {
    // data = encrypt(data);
   const  data = {topic_id: topicID, option_id: optionID};
   console.log(token,data )
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route: `v1/questions/answer`,
        data:data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}
export const getTopicAnsStatus = (token, topicID,controller) => {
    // data = encrypt(data);
    return invoke({
        method: 'GET',
        baseURL: BASE_PATH,
        route: `v1/questions/topicAnsStatus?id=${topicID}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
        , signal: controller?.signal,
    });
}
