import Login from "./Components/Auth/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import Pools from "./Components/Pools/Pools";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'react-loading-skeleton/dist/skeleton.css'
import MainContainer from "./Components/Layout/MainContainer";
import ProtectedContainer from "./Components/Layout/ProtectedContainer";
function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
         <Route path="/auth"  element={<MainContainer />}>
            {/* <Route path="/" element={<Login />} /> */}
            <Route path="login" element={<Login />} />
          </Route>

          <Route path="/" element={<ProtectedContainer />}>
            <Route path="/" element={<Pools />} />
          </Route>

       

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
