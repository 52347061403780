import React from "react";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
class Dashboard extends React.Component {
    render() {
        return (
            <>
                <Header></Header>
                {/* <Sidebar></Sidebar> */}
            </>
        )
    }
}
export default Dashboard;