import React, { useState } from "react";

export default (props) => {

  const { id, title, message } = props;
  return (
    <React.Fragment>
            <button style={{display: 'none'}} id={id} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#messageBoxModal-${id}`}>
             
            </button>
            <div className="modal fade DarkModal" data-bs-backdrop="static" data-bs-keyboard="false" id={`messageBoxModal-${id}`}  aria-labelledby="messageBoxModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header align-items-start">
                            <h5 className="modal-title" id="messageBoxModalLabel">{ title }</h5>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                { message }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button data-bs-toggle="modal" data-bs-target={`#messageBoxModal-${id}`} type="button" className="btn ">Cancel</button> 
                            <button onClick={() => {
                              props.onConfirmPress();
                              document.getElementById(id).click();
                            }} data-bs-toggle="modal" type="button" className="btn BtnBlack py-2 px-5 color-white">Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    // <Modal
    //   {...props}
    //   size="md"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    // >
    //   <div className="bg-modal ">
    //     <Modal.Header>
    //       <Modal.Title id="contained-modal-title-vcenter ">
    //         <h5 className="modal-title">{props.title ?? ""}</h5>
    //       </Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       <p className="mb-4">{props.message ?? ""}</p>
    //     </Modal.Body>
    //     <Modal.Footer>
    //       <button
    //         type="button"
    //         className="btn BtnBorderBlack py-2 px-3 px-md-5"
    //         onClick={props.onCancelPress}
    //       >
    //         {props?.cancelBtnTitle ?? "Cancel"}
    //       </button>
    //       <button
    //         onClick={props.onConfirmPress}
    //         type="button"
    //         className="btn BtnBlack py-2 px-3 px-md-5"
    //       >
    //         {props?.confirmBtnTitle ?? "Confirm"}
    //       </button>
    //     </Modal.Footer>
    //   </div>
    // </Modal>
  );
};
