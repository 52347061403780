import {
    Outlet,
    Navigate
  } from "react-router-dom";
 
  export default  ()=> {
    const token = localStorage.getItem("voting_token") 

    if (!token) {
      return <Navigate to="/auth/login" />;
    }

    return (
      <div>
 
        <div className="content">
          <Outlet />
        </div>
      </div>
    );
  
  }
  
  