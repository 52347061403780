import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getProfile } from "../../api/authentication/authentication";

const Header = () => {

    const navigate = useNavigate();

    const [ username, setUsername ] = useState();

    getProfile().then(({ data: { user_name }}) => {
        setUsername(user_name);
    })

    const onLogoutClick = () => {
        localStorage.removeItem("voting_token");
        navigate("/auth/login")
    }

    return (
        <>
            <header className="px-3 shadow-sm">
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="">
                            <a href="/" className="logo logo-dark">
                                <span className="logo-sm">
                                <img src="https://nucoin.net.au/assets/images/nucoin-logo.png" alt="" />
                                </span>
                                <span className="logo-lg">
                                    <Link to="/" class="navbar-brand d-flex justify-content-center align-items-center" href="/">
                                        <span class="Logo-Txt">NUC</span>
                                        <img src="https://nucoin.net.au/assets/images/nucoin-logo.png" alt="" />
                                        <span class="Logo-Txt">IN</span>
                                    </Link>
                                </span>
                            </a>
                            <a href="/" className="logo logo-light">
                                <span className="logo-sm">
                                <img src="https://nucoin.net.au/assets/images/nucoin-logo.png" alt="" />
                                </span>
                                <span className="logo-lg">
                                <img src="https://nucoin.net.au/assets/images/nucoin-logo.png" alt="" />
                                    <span className="logo-txt">NU Coin Voting</span>
                                </span>
                            </a>
                        </div>
                        <button
                            type="button"
                            className="btn btn-sm px-3 header-item vertical-menu-btn noti-icon"
                        >
                            
                            <i className="fa fa-fw fa-bars font-size-16" />
                        </button>
                    </div>
                    

                    <div className="d-flex">

                    

                        <div className="dropdown d-inline-block">
                            <button
                                type="button"
                                className="btn header-item user text-start d-flex align-items-center"
                                id="page-header-user-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                { username }
                                
                                <span className="ms-2 d-none d-xl-inline-block user-item-desc">
                                    
                                    <span className="user-name">
                                        <i className="mdi mdi-chevron-down" />
                                    </span>
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end pt-0">
                                <h6 className="dropdown-header">
                                    {/* Welcome */}
                                    </h6>
                                {/* <a className="dropdown-item" href="pages-profile.html">
                                    <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1" />{" "}
                                    <span className="align-middle">Profile</span>
                                </a> */}
                                <a style={{ cursor: 'pointer' }} className="dropdown-item" onClick={onLogoutClick}>
                                    <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1" />{" "}
                                    <span className="align-middle">Logout</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="collapse show verti-dash-content" id="dashtoggle">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Text Here</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <a
                        className="dash-troggle-icon"
                        id="dash-troggle-icon"
                        data-bs-toggle="collapse"
                        href="#dashtoggle"
                        aria-expanded="true"
                        aria-controls="dashtoggle"
                    >
                        <i className="bx bx-up-arrow-alt" />
                    </a>
                </div> */}
            </header>

        </>
    )

}
export default Header;