import React, { useRef, useState } from "react";
import { login } from "../../api/authentication/authentication";
import { useNavigate, withRouter } from 'react-router-dom';
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

const Login = () => {

    const refEmailField = useRef()
    const refPassField = useRef()
    const navigate = useNavigate();
    const [state, setState] = useState({});

    const doLogin = () => {

        if (refEmailField.current.value.trim() == "") {
            setState({ message: "Please enter your username." })
        } else if (refPassField.current.value.trim() === "") {
            setState({ message: "Please enter your password." })
        }
        else {

            const data = {
                user_name: refEmailField.current.value.trim(),
                password: refPassField.current.value.trim()
            }
            setState({ message: null, isLoading: true })

            login(data).then(res => {

                localStorage.setItem("voting_token", res.data.token);
                setState({ message: null, isLoading: false })
                navigate('/');
            }).catch(error => {

                setState({ message: error.response?.data ?? "", isLoading: false })

            })
        }
    }

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex">
                    <div className="col-xl-3 col-lg-4 col-md-5">
                        <div class="d-flex flex-column h-100 py-5 px-4">
                            <div class="text-center text-muted mb-2">
                                <div class="pb-3">
                                    <span class="logo-lg">
                                        <Link to="/" class="navbar-brand d-flex justify-content-center align-items-center" href="/">
                                            <span class="Logo-Txt">NUC</span>
                                            <img src="https://nucoin.net.au/assets/images/nucoin-logo.png" alt="" />
                                            <span class="Logo-Txt">IN</span>
                                        </Link>
                                    </span>
                                    <p class="text-muted font-size-15 w-75 mx-auto mt-3 mb-0">Sign in to continue to NuCoin Voting.</p>
                                </div>
                            </div>

                            {/* <div class="my-auto">
                                <div class="p-3 text-center">
                                    <img src="/assets/images/auth-img.png" alt="" class="img-fluid" />
                                </div>
                            </div> */}


                            <div className="card border-0 my-auto">
                                <div className="card-body">
                                    <div className="px-3 py-3">
                                        <div className="">
                                            <h5 className="mb-0">Welcome Back !</h5>
                                            {/* <p className="text-muted mt-2">
                                                Sign in to continue to NU Coin Voting.
                                            </p> */}
                                        </div>
                                        <div className="mt-4 pt-2">
                                            <div className="form-floating mb-3">
                                                <label htmlFor="input-username">Username</label>
                                                <input
                                                    ref={refEmailField}
                                                    type="text"
                                                    className="form-control"
                                                    id="username"
                                                    name="username"
                                                    onKeyDown={e => {
                                                        if (e.key === 'Enter') {
                                                            doLogin()
                                                        }
                                                    }}
                                                    placeholder="Enter User Name"
                                                />
                                                {/* <div className="form-floating-icon">
                                                            <i className="uil uil-users-alt" />
                                                        </div> */}
                                            </div>
                                            <div className="form-floating mb-3 auth-pass-inputgroup">
                                                <label htmlFor="password-input">Password</label>
                                                <input
                                                    ref={refPassField}
                                                    type="password"
                                                    className="form-control"
                                                    id="password-input"
                                                    placeholder="Enter Password"
                                                    name="password"
                                                    onKeyDown={e => {
                                                        if (e.key === 'Enter') {
                                                            doLogin()
                                                        }
                                                    }}
                                                />
                                                {/* <button
                                                            type="button"
                                                            className="btn btn-link position-absolute h-100 end-0 top-0"
                                                            id="password-addon"
                                                        >
                                                            <i className="mdi mdi-eye-outline font-size-18 text-muted" />
                                                        </button> */}
                                                {/* <div className="form-floating-icon">
                                                            <i className="uil uil-padlock" />
                                                        </div> */}
                                            </div>



                                            <div className="mt-3">
                                                <button className="btn btn-primary w-100 centerItems" onClick={doLogin}>
                                                    Log In
                                                    {state?.isLoading && <CircularProgress style={{ width: 15, height: 15, marginLeft: 15, marginRight: 10, color: '#ffffff' }} />}

                                                </button>
                                            </div>

                                            <div className="text-center">
                                                <p className="mt-2 color-red">
                                                    {state?.message ?? ""}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4 mt-md-5 text-center">
                                <p class="mb-0">© NuCoin Voting</p>
                            </div>
                        </div>
                        {/* <div className="d-flex flex-column h-100 py-5 px-4">
                                <div className="text-center text-muted mb-2">
                                    <div className="pb-3">
                                        <p className="text-muted font-size-15 w-75 mx-auto mt-3 mb-0">
                                            User Experience &amp; Interface Design Strategy Saas Solution
                                        </p>
                                        <a >
                                            <span className="logo-lg">
                                                <img src="../assets/images/auth-img.png" alt="" />
                                                <span className="logo-txt"></span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div className="my-auto">
                                    <div className="p-3 text-center">
                                        <img
                                            src="assets/images/auth-img.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="mt-4 mt-md-5 text-center">
                                    <p className="mb-0">
                                        © NU Coin Voting
                                       
                                    </p>
                                </div>
                            </div> */}
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-7 p-0 display-none">
                        <div className="auth-bg bg-light py-md-5 p-4 d-flex">
                            <div className="bg-overlay-gradient" />
                            <div className="row justify-content-center g-0 align-items-center w-100">
                                <div className="col-xl-4 col-lg-8">
                                    {/* <div className="card">
                                        <div className="card-body">
                                            <div className="px-3 py-3">
                                                <div className="text-center">
                                                    <h5 className="mb-0">Welcome Back !</h5>
                                                    <p className="text-muted mt-2">
                                                        Sign in to continue to NU Coin Voting.
                                                    </p>
                                                </div>
                                                <div className="mt-4 pt-2">
                                                    <div className="form-floating mb-3">
                                                        <label htmlFor="input-username">Username</label>
                                                        <input
                                                            ref={refEmailField}
                                                            type="text"
                                                            className="form-control"
                                                            id="username"
                                                            name="username"
                                                            onKeyDown={e => {
                                                                if (e.key === 'Enter') {
                                                                    doLogin()
                                                                }
                                                            }}
                                                            placeholder="Enter User Name"
                                                        />
                                                    </div>
                                                    <div className="form-floating mb-3 auth-pass-inputgroup">
                                                        <label htmlFor="password-input">Password</label>
                                                        <input
                                                            ref={refPassField}
                                                            type="password"
                                                            className="form-control"
                                                            id="password-input"
                                                            placeholder="Enter Password"
                                                            name="password"
                                                            onKeyDown={e => {
                                                                if (e.key === 'Enter') {
                                                                    doLogin()
                                                                }
                                                            }}
                                                        />
                                                    </div>



                                                    <div className="mt-3">
                                                        <button className="btn btn-primary w-100 centerItems" onClick={doLogin}>
                                                            Log In
                                                            {state?.isLoading && <CircularProgress style={{ width: 15, height: 15, marginLeft: 15, marginRight: 10, color: '#ffffff' }} />}

                                                        </button>
                                                    </div>

                                                    <div className="text-center">
                                                        <p className="mt-2 color-red">
                                                            {state?.message ?? ""}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Login