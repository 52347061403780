import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { getQuestionsList } from "../../api/questions/authentication";

const ITEMS_LIMIT = 10;

const Sidebar = (props)=> {

    const [state, setState] = useState({isLoading:false,listData:[], selectedQuestion:null, hasMoreData:false})
    const getQuestionList = ()=>{
 
        setState(state=>({...state,isLoading:true}))
        getQuestionsList(state.listData?.length ?? 0,ITEMS_LIMIT).then(res=>{

            if(state.selectedQuestion == null)
                setState(state=>({...state,isLoading:false,listData:[...state.listData,...res.data],selectedQuestion:res.data?.length>0 ? res.data[0] : null, hasMoreData : res.data?.length>=ITEMS_LIMIT }))
            else
                setState(state=>({...state,isLoading:false,listData:[...state.listData,...res.data],hasMoreData : res.data?.length>=ITEMS_LIMIT}))

            props?.setSelectedItem(res.data?.length>0 ? res.data[0] : null)
        }).then(error=>{
            setState(state=>({...state,isLoading:false}))
        })
    }
    
   useEffect(()=>{
       
        getQuestionList()
   },[])
   
   const onItemSelected = (item) => {
        setState(state=>({...state,selectedQuestion:item}))
        props?.setSelectedItem(item)
   }


   const loadMoreData = ()=>{
       getQuestionList()
   }

    return (
        <>
        {/* width-300px */}
        
            <div className="col-lg-2 col-md-4 overflow-height shadow-sm bg-offwhite">
         
                <div data-simplebar="" className="">
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled chat-message-list" id="side-menu">
                            <li className="menu-title" data-key="t-menu" key={"menu-title"}>
                                Questions  
                            </li>
                           
                            {
                                state.listData?.map((item)=>{
                                    return (<li  key={"question-"+item.id} className={`active cursor-pointer ${item.id == state.selectedQuestion?.id ? 'bg-blue-transprent' :''}`} onClick={()=>onItemSelected(item)}>
                                        <span title={item.name} className="d-block p-2">
                                            <div className="d-flex align-items-start my-1 mx-2">
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-14 mb-1 w-100" style={{ color: "#000" }}>
                                                    {item.name}
                                                    </h5>
                                                    {/* <p className="text-truncate mb-0">
                                                    {item.description}
                                                    </p> */}
                                                </div>
                                            
                                            </div>
                                        </span>
                                </li> )
                                })
                            }
                             {state.isLoading && <li key={"Skeleton"} ><Skeleton count={5} height={60}  style={{marginLeft:10}}/></li>}
                             {!state.isLoading && state.hasMoreData && ( <div className="d-flex mt-3">
                                <div className="flex-grow-1">
                                    
                                </div>
                                <div className="flex-shrink-0 mx-2">
                                    <div  className="link-primary btn-primary p-2 rounded mt-3 cursor-pointer" onClick={loadMoreData}>Load More</div>              
                                </div>
                            </div>)}
                           

                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
    
}
export default Sidebar;