import invoke from '../../utils/invoke';
import { encrypt } from '../../utils/utils';

const BASE_PATH = process.env.REACT_APP_AUTH_SERVICE || 'http://localhost:2001';

export const login = (data) => {
    data = encrypt(data);
    
    return invoke({
        method: 'POST',
        baseURL: BASE_PATH,
        route: 'v1/auth/login',
        data: data
    });
}


export const getProfile = () => {
    
    return invoke({
        method: 'GET',
        baseURL: process.env.REACT_APP_PROFILE_SERVICE,
        route: 'v1/profile',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('voting_token')}`
        },
    });
}

