
module.exports.CERTIFICATE_PUB = `-----BEGIN PUBLIC KEY-----
MIIEIjANBgkqhkiG9w0BAQEFAAOCBA8AMIIECgKCBAEAtwOZc3jbOF5eTeOJ13hH
LXqhZutmhdHIvFn1sUlbCqYKS3LDFqkgYjZMcOPwnSh/mzR9MITXoUTJhSYyjy67
kcxxDQGiiXnDjZpnQ46vyWuKEKLsy8qLHR8CU6BpH8oAvVZiv6LqTpBNXw6j57/P
WYgmqzXXpH6uGQ09ztUHkXRBBrGJ8dMagpbtns/KPjJLjZQJF6O0TEE0x7lPps8e
wYQRs8j/a2TX1tGrWPMNAMSqMh9j1yGaZ/oMz4tvIYKOK9EBRz1EogB250nmkDGc
zAs5kInrMEexO8usoWun/9WSY56KnU3MTnvW05sBAlRaeJIA28ocfckGwVQnezGM
pGD1fFGMw63eRk79FhzSYrv6xCNgynZxtUz3pJAjsztEoQrcDVyPiZBTjQMUcca6
f/mrpFRUgkbG7Yzyel3EoQPmBIqDynCDZq7P+92P+7N7p0DnGAcsvrmZnSLKbW1p
c0XkYA0MgCm3vK0liJt9u8Z4py162EgbSiUNR1MROO6PJEt05OpxasC7+4raKq2Z
H+l/emqKYRuJvbN9pLRKyjHfwQodcNFG/SUs2dm6E7FJbsnmkzal5c2lUgQExgXx
Pxbbi09PADN+3pt//lRb5JWjGqE7iLj/4sSk/qLcM1bnPRZ0plQNYcaqREX2GFpZ
uWOM3MQp9sZ2dDvLOViobvgm5JWe6dpeF4MzqhqlukKIBuPHfsT3rDiHKzbhI0v9
x9xDU2r3pTWAEQ2iu8veOBgtPTrH3esKPb61ylJX+/805XXxPFcqcUAZ2dlsss70
0GNKxijkqjBFBQ548O1a9dxH+ftK/n5slDGRbAibWz+EUGM4hOfkqh5/bz2lLIZj
O1FGUTmYWlQvg+Ne79Ay0H5fWZYHWj45LqBp0kXSU4JmH8NdqM1KJtGBcDNk+sRs
ntnvp0ZIvanPZF/Pp+A4v6dkWKxffoGupLAK67ckDRQQHqKNVOqmLuEpQomWCGxG
u83XKDCMpzEXf2zLCxnvQuatOA99XVaXywuJwyx0NQWZsYd/ZPc95Tys22ZRT8rL
JFXGJzqygMd9PEa2/f34RM3tnBVhjoo96U3SohgBdxVsh26iBpEIQPLVkUnLKiHK
p1HlcOxx+VvR2PTW1oO2VKlQ7aCZymFyqmN+yCbxV76MvRlcqLMIViZPKzS5fspu
eCIC9N4O6buFblyz4s/36HY+8QuswLpHatEX11GjwORhJ63v5XrQEgXfxd1MY8M7
neo7idJZSH20Jttf7xPTsUbY+S3LwNFGv1fCfBsFUFLdfor108qDlfsfbHmug0xc
DcmgfIGmBYGbnlebVU2RVawRKwOeRDUlTV4vs8e4a9GrSiT2we1AKxNELGjKViC9
IwIDAQAB
-----END PUBLIC KEY-----
`;
