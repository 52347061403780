import React, { useEffect, useRef, useState } from "react";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import { getOptionsList,addTopicAnswer } from "../../api/questions/authentication";
import { CircularProgress } from "@material-ui/core";
// import SkeletonLoading from "../Layout/SkeletonLoading/SkeletonLoading";
import Skeleton from 'react-loading-skeleton';
import PoolStatus from "./PoolStatus";
import ConfirmationModal from "../Modals/ConfirmationModal";


const Pools = ()=>{

    const [state, setState] = useState({isLoading:false,isSubmiting:false,selectedOptionID:null,selectedOption:null})
    const abortControler = useRef();
    const refStatusComp = useRef();

    const setSelectedItem = (item)=>{
        setState(state=>({...state,selectedQuestion:item, options:null}))
    }
    const getOptionsListFromApi = (showLoader)=>{
  
        if(state.selectedQuestion?.id)
        {
            setState(state=>({...state,isLoading:showLoader,selectedOptionID:null}))
            if (abortControler?.current !== null) {
                abortControler?.current?.abort();
              }
          
              abortControler.current = new AbortController();

            getOptionsList(localStorage.getItem("voting_token"),state.selectedQuestion.id, abortControler.current).then(res=>{
              
                setState(state=>({...state,isLoading:false,selectedOptionID:res.data?.selectedOption?.topic_option,selectedOption:res.data?.selectedOption,options:res.data?.options}))
             
            }).then(error=>{
                setState(state=>({...state,isLoading:false}))
            })
        }
        
    }
    const handleChangeRadio = e => {
        setState(state=>({...state,selectedOptionID:e.target.id}))
        
    };
    const submitConfirmation = ()=>{
        document.getElementById(`vote-confirmation`).click();
    
        
    }
    const getOptionTitle = ()=>{

        const itemFound = state.options?.find((item)=>{
            return item.id == state.selectedOptionID
        })

        return itemFound?.option_value ?? ""
    }
    const submitAnswer = ()=>{
        setState(state=>({...state,isSubmiting:true}))
        addTopicAnswer(localStorage.getItem("voting_token"),state.selectedQuestion.id,state.selectedOptionID).then(res=>{
         
            getOptionsListFromApi(false)
            setState(state=>({...state,isSubmiting:false}))
            refStatusComp.current.refreshStatusData();
            
        }).then(error=>{
            setState(state=>({...state,isSubmiting:false}))
        })
        
    }
    useEffect(()=>{
       
        getOptionsListFromApi(true)
    },[state.selectedQuestion?.id])


    return (
        <>
            <Header/>
            <div className="row h-100">

           
                <Sidebar setSelectedItem={setSelectedItem}/>
                <div className="col-lg-10 col-md-8 w-100">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row mb-4">
                                
                                <div className="col-12">
                                    <div className="w-100 user-chat mt-4 mt-sm-0 ms-lg-3">
                                        <div className="card p-3">
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <div className="card h-100 mb-lg-0">
                                                        <div className="card-body">
                                                            <h5 className="card-title mb-2">{state.selectedQuestion?.title ?? ""}</h5>
                                                            <p style={{ fontSize: '14px' }} className="mb-4 color-gray ">
                                                                {state.selectedQuestion?.description ?? ""}
                                                            </p>
                                                            <div>
                                                                
                                                                {state.options?.map(item=>{

                                                                    return ( <div className="form-check mb-2 mt-3" key={item.id}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="formRadios"
                                                                        id={item.id}
                                                                        key={item.id}
                                                                        defaultChecked={item.id === state.selectedOptionID}
                                                                        onChange={handleChangeRadio}
                                                                        disabled={state.selectedOption!=null}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="formRadios1">
                                                                    {item.option_value}
                                                                    </label>
                                                                </div>)
                                                                })}
                                                                
                                                                {state.isLoading && <Skeleton count={4} height={25}  width={180}/>}
                                                                
                                                                <div className="mt-5" onClick={submitConfirmation}>
                                                                    {state.selectedOptionID && !state.selectedOption && (<button className="btn btn-primary px-3 centerItems" >
                                                                    Submit
                                                                    { state.isSubmiting && <CircularProgress style={{width:15, height:15, marginLeft:15, marginRight:10, color:'#ffffff'}}/>}

                                                                </button>)}
                                                            
                                                            </div>

                                                            </div>

                                                        </div>
                                                        {/* end card body */}
                                                    </div>
                                                    {/* end card */}
                                                </div>
                                                {/* end col */}
                                                <div className="col-lg-4">
                                                <PoolStatus id={state.selectedQuestion?.id} ref={refStatusComp}/>
                                                    {/* end card */}
                                                </div>
                                                {/* end col */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmationModal
                    id="vote-confirmation"
                    title={"Vote Confirmation?"}
                    message={<p>Are you sure you want to cast vote for <strong>{getOptionTitle()}</strong>?</p>}
                    onConfirmPress={submitAnswer}
                />
             </div>
        </>
    )

}
export default Pools;